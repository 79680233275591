import { ref } from 'vue';
import { set } from 'vue-demi';
import { defineStore } from 'pinia';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useApiClient } from '@/plugins/axios';
import { surveyApiConfig } from '@/config/api/survey';

type Answer = string|number|Array<string|number>;

type Answers = Record<number, Answer>;

interface Option {
    id: number;
    title: string;
    description: string;
    hint: string;
    slug: string;
    nextQuestion?: number;
  }
interface Question {
  id: number;
  title: string;
  description: string;
  type: 'single'|'multiple';
  initialValue: number;
  answerDefault: number;
  required: boolean;
  options: Array<Option>;
  slug: string;
}

export const useSurveyStore = defineStore('survey', () => {
  const questions = ref<Array<Question>>([]);
  const questionsIds = ref<Array<number>>([]);
  const activeQuestionIndex = ref<number>(0);
  const currentQuestion = ref<Question|null>(null);
  const answers = ref<Answers>({});
  const history = ref<Array<number>>([]);
  const currentStep = ref<number>(0);

  const setQuestions = (payload: Array<Question>) => {
    questions.value = payload;
    questionsIds.value = payload.map((question) => question.id);
    currentQuestion.value = payload[0];
  };

  const setActiveIndex = (payload: number) => {
    activeQuestionIndex.value = payload;
  };

  const setCurrentQuestion = (payload: Question) => {
    currentQuestion.value = payload;
  };

  const setAnswer = ({ id, answer }: { id: number; answer: Answer }) => {
    set(answers.value, id, answer);
  };

  const setDefaultState = () => {
    questions.value = [];
    activeQuestionIndex.value = 0;
    currentQuestion.value = null;
    answers.value = {};
  };

  const setQuestionsHistory = ({ id, action }: { id?: number; action: string }) => {
    if (action === 'next' && id) {
      history.value.push(id);
    }

    if (action === 'previous') {
      history.value.pop();
    }
  };

  const setStep = (step: number) => {
    currentStep.value = step;
  };

  const getQuestions = async ({ bookingId, slug }: { bookingId: number; slug: string }) => {
    const url = surveyApiConfig.getQuiz(slug);
    const questionsData = await useApiClient().$get(url, {
      params: {
        booking_id: bookingId
      }
    });
    setDefaultState();
    setQuestions(questionsData);
  };

  const nextQuestion = (answer: Answer | Array<Answer>) => {
    if (!currentQuestion.value?.id) {
      return;
    }
    const { id, options } = currentQuestion.value;

    setQuestionsHistory({
      id,
      action: 'next'
    });

    const currentOption = options.find((option: Option) => {
      if (Array.isArray(answer)) {
        return option.id === answer[0];
      }

      return option.id === answer;
    });

    const nextQuestion = questions.value.find((question: Question) => question.id === currentOption?.nextQuestion);

    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      const nextQuestionIndex = questionsIds.value.indexOf(nextQuestion.id);
      setActiveIndex(nextQuestionIndex);
    } else {
      const currentQuestionIndex = questionsIds.value.indexOf(id);
      setCurrentQuestion(questions.value[currentQuestionIndex + 1]);
      setActiveIndex(currentQuestionIndex + 1);
    }
  };

  const previousQuestion = () => {
    const lastQuestionId = history.value.at(-1) ?? 0;
    const lastQuestionIndex = questionsIds.value.indexOf(lastQuestionId);

    setActiveIndex(lastQuestionIndex);
    const prevQuestion = questions.value.find((question: Question) => question.id === lastQuestionId);

    if (prevQuestion) {
      setCurrentQuestion(prevQuestion);
      setQuestionsHistory({ action: 'previous' });
    }
  };

  const setActiveQuestionAnswer = async ({ answer, bookingId }: { answer: Answer; bookingId: number }) => {
    if (!currentQuestion.value) {
      return;
    }
    const { id } = currentQuestion.value;
    const url = surveyApiConfig.sendQuestion(id);
    await useApiClient().$patch(url, {
      option: answer,
      bookingId: Number(bookingId)
    });
    setAnswer({
      id,
      answer
    });
  };

  const setFinishedQuiz = async ({ bookingId, slug }: { bookingId: number; slug: string }) => {
    const url = surveyApiConfig.finishedQuiz(slug);
    await useApiClient().$put(url, {
      bookingId: Number(bookingId)
    });
  };

  const decoratedApiActions = decorateApiActions({
    getQuestions,
    setActiveQuestionAnswer,
    setFinishedQuiz
  }, 'store/survey', true);

  return {
    questions,
    questionsIds,
    activeQuestionIndex,
    currentQuestion,
    answers,
    history,
    currentStep,
    ...decoratedApiActions,
    nextQuestion,
    previousQuestion,
    setStep
  };
});
