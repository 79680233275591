import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  decorateApiActions
} from '@@/shared/utilites/logging';
import lotDetailInfo from '../queries/lotDetailInfo.graphql';
import lotDetailInfoForDeal from '../queries/lotDetailInfoForDeal.graphql';
import { Flat as BaseFlat } from '../types/Flat';
import { WindowView } from '@/types/WindowView';
import { usePortalApi } from '@/plugins/api/portal';

interface Flat extends BaseFlat {
  planPng: string;
  planHover: string;
  windowView: WindowView;
}

export const useFlatStore = defineStore('flat', () => {
  const flat = ref<Flat | undefined>(undefined);

  function reset () {
    flat.value = undefined;
  }

  async function getFlat (globalId: string): Promise<void> {
    const portalApi = usePortalApi();
    const { data } = await portalApi.post('/graphql/', {
      query: lotDetailInfo?.loc?.source?.body,
      variables: {
        id: globalId
      }
    });

    const fetchedFlat = data.data?.lotDetailInfo;
    flat.value = fetchedFlat;

    if (!fetchedFlat) {
      throw new Error('Нет квартиры');
    }
  }

  /**
   * Запрос на портал за данный об недвижимости,
   * для отображения на детальной страницы сделки
   * @param{string} globalId портальный ID квартиры (лота)
   * */
  async function getFlatForDealDetail (globalId: string) {
    const portalApi = usePortalApi();
    const { data } = await portalApi.post('/graphql/', {
      query: lotDetailInfoForDeal?.loc?.source?.body,
      variables: {
        id: globalId
      }
    });

    return data.data?.lotDetailInfo;
  }

  return {
    flat,
    reset,
    ...decorateApiActions({
      getFlat,
      getFlatForDealDetail
    }, 'store/flat.ts')
  };
});
