import { Role } from '@/store/authStore';

const bookingApi = {
  booking: {
    createBooking: (role: Role) => `/api/users/${ role }/create_booking`,
    bookRealty: () => '/api/properties/bind',
    properties: () => '/api/properties',
    unbookingProperty: () => '/api/properties/unbind',
    extendClientFixation: (bookingId: number) => `/api/booking/extend_client_fixation/${ bookingId }`,
    freeBooking: () => '/api/dsl/free_booking',
    categoriesFreeBooking: '/api/dsl/categories_free_booking',
    bookingByUser: '/api/dsl/booking_by_user',
    taskInstance: (taskInstanceId: number) => `/api/task_management/task_instance/${ taskInstanceId }`
  }
};

export default bookingApi;

export const bookingApiConfig = bookingApi.booking;
