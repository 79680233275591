const surveyApi = {
  survey: {
    getQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }`,
    sendQuestion: (questionId: number) => `/api/agents/questionnaire/${ questionId }`,
    finishedQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }/finish`
  }
};

export default surveyApi;

export const surveyApiConfig = surveyApi.survey;
