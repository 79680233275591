import { defineStore } from 'pinia';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { KnowledgeResponse } from '@/types/Knowledge';
import { useAxios } from '@/plugins/app-context';
import { knowledgeApiConfig } from '@/config/api/knowledge';

export const useKnowledgeStore = defineStore('knowledge', () => {
  async function getItems (citySlug: string): Promise<KnowledgeResponse> {
    const url = knowledgeApiConfig.list;
    const { data } = await useAxios().get<KnowledgeResponse>(url, {
      params: {
        city_of_sale: citySlug
      }
    });

    return data;
  }

  async function getFolders (folderId: number): Promise<KnowledgeResponse> {
    const url = knowledgeApiConfig.list;
    const { data } = await useAxios().get<KnowledgeResponse>(url, {
      params: {
        folder_id: folderId
      }
    });

    return data;
  }

  const decoratedRethrow = decorateApiActions({
    getItems,
    getFolders
  }, 'store/knowledge', true);

  return {
    ...decoratedRethrow
  };
});
