const mainPageApi = {
  mainPage: {
    sellOnline: '/api/broker/main_page/content/sell_online',
    partnerLogo: '/api/broker/main_page/content/partner_logo',
    offer: '/api/broker/main_page/content/offer',
    headOfPartnersDepartment: '/api/managers/head_of_partners_department'
  }
};

export default mainPageApi;

export const mainPageApiConfig = mainPageApi.mainPage;
