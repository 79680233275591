import { ref } from 'vue';
import {
  defineStore,
  storeToRefs
} from 'pinia';
import {
  decorateApiActions
} from '@@/shared/utilites/logging';
import { moljaninovoSlug } from '@@/shared/constants/projects';
import { LotType } from '@@/shared/constants/flats';
import { PropertyTypeSlug } from '@/types/Deal';
import { usePortalApi } from '@/plugins/api/portal';
import allGlobalFlats from '@/queries/allGlobalFlats.graphql';
import { useProjectsStore } from '@/store/projects';
import { Flat } from '@/types/Flat';

interface GlobalFlat {
  node: Flat;
}

export interface Commercial {
  id: string;
  pk: string;
  article: string;
  number: string;
  area: number;
  price: number;
  hidePrice: false;
  originalPrice: number;
  fullFinalPrice: number;
  project: {
    name: string;
    slug: string;
    city: {
      name: string;
      slug: string;
    };
  };
  floor: {
    number: number;
  };
  plan: string;
  type: PropertyTypeSlug;
}

export const useCommercialStore = defineStore('commercial', () => {
  const commercialList = ref<Array<Commercial>>([]);

  const getGlobalFlats = async (projectId: string) => {
    const portalApi = usePortalApi();

    /* TODO переделать на allGlobalFlatsV2 */
    return portalApi.$post('/graphql/', {
      query: allGlobalFlats.loc?.source.body,
      variables: {
        project: projectId,
        type: LotType.commercial
      }
    });
  };

  /**
   * Временно захардкожено.
   * Ищем проект по слагу
   * */
  async function loadCommercial (): Promise<void> {
    const projectsStore = useProjectsStore();
    const { getGlobalProjects } = projectsStore;
    const { getterGlobalProjects } = storeToRefs(projectsStore);
    await getGlobalProjects();

    const moljaninovoProject = getterGlobalProjects.value
      .find((item) => item.slug === moljaninovoSlug);
    const { globalId } = moljaninovoProject ?? {};

    if (!globalId) {
      throw new Error('globalId is not defined ~~ loadCommercial');
    }

    const list = await getGlobalFlats(globalId);
    commercialList.value = list.data.allGlobalFlats.edges
      .reduce((acc: Array<Flat>, item: GlobalFlat) => {
        acc.push(item.node);

        return acc;
      }, []);
  }

  return {
    commercialList,
    ...decorateApiActions({
      loadCommercial
    }, 'store/commercial.ts')
  };
});
