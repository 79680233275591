// Стор для feature flags
// import { useFeatureFlagsStore, FLAGS } from '@/store/featureFlags';
// При переходе между страницами с бэка запрашиваем список флагов
// В компоненте используем геттер
// setup () {
//   const featureFlagsStore = useFeatureFlagsStore();
//   const { isEnabledFlag } = featureFlagsStore;
//   return {
//     isEnabledFlag
//   };
// },
// Использовать
// v-if = isEnabledFlag(FLAGS[slug])

import { decorateApiActions } from '@@/shared/utilites/logging';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAxios } from '@/plugins/app-context';

type Flags = Record<string, boolean>;

/* список флагов которые используются в проекте */
export const FLAGS = {
  expiredDateStatus: 'del002-18_f', // дата понижения статуса ПЛ
  catalogNewFilters: 'strana_lk-4771', // новые фильтры в каталоге
  support: 'strana_lk-4438_f' // ФОС
} as const;

type TFlags = typeof FLAGS
type TFlagsValues = TFlags[keyof TFlags]
const flags: Array<TFlagsValues> = Object.values(FLAGS);

export const useFeatureFlagsStore = defineStore('featureFlagsBroker', () => {
  const featureFlags = ref<Flags>({});

  async function getFlags (): Promise<void> {
    const url = 'api/feature-flags';

    if (!flags.length) {
      return;
    }

    const {
      data
    } = await useAxios().get(url, {
      params: {
        feature_flag: flags
      }
    });

    featureFlags.value = {
      ...data
    };
  }

  /**
   * Использовать вместе с константой FLAGS из этого же файла
   */
  function isEnabledFlag (flag: TFlagsValues): boolean {
    return Boolean(featureFlags.value[flag]);
  }

  return {
    featureFlags,
    isEnabledFlag,
    ...decorateApiActions({ getFlags }, 'featureFlags.ts'),
    hasCatalogNewFilters: () => isEnabledFlag(FLAGS.catalogNewFilters),
    isExpiredDateStatusEnabled: () => isEnabledFlag(FLAGS.expiredDateStatus)
  };
});
