import type { Strategy } from '@/store/authStore';

const fireApi = {
  fire: {
    agent: '/api/agencies/agent/fire',
    byRole: (strategy: Strategy) => `/api/${ strategy }/fire_agent`
  }
};

export default fireApi;

export const fireApiConfig = fireApi.fire;
