import {
  ref,
  computed
} from 'vue';
import { defineStore } from 'pinia';
import { decorateApiActions } from '@@/shared/utilites/logging';
import allCities from '../queries/allCities.graphql';
import { GlobalCity } from './cities';
import { useApiClient } from '@/plugins/axios';
import { usePortalApi } from '@/plugins/api/portal';
import { documentsApiConfig } from '@/config/api/documents';

interface InterfaceFile {
  aws: string;
}

interface ReglamentResponse {
  slug?: string;
  file: InterfaceFile;
  text: string;
}
interface Reglament {
  data: ReglamentResponse;
  isFetched: boolean;
}

export const useGlobalStore = defineStore('global', () => {
  const activeCity = ref<GlobalCity|null>(null);
  const citiesGlobal = ref<Array<GlobalCity>>([]);
  const reglament = ref<Reglament>({
    data: {
      file: {
        aws: ''
      },
      text: ''
    },
    isFetched: false
  });

  const getReglamentLink = computed(() => reglament.value?.data?.file?.aws || '');
  const getReglamentText = computed(() => reglament.value?.data?.text || '');
  const getActiveCity = computed(() => activeCity.value);
  const getGlobalCities = computed((): Array<GlobalCity> => citiesGlobal.value);

  const setReglament = (payload: ReglamentResponse) => {
    reglament.value.data = payload;
    reglament.value.isFetched = true;
  };

  const getAllCities = async () => {
    const portalApi = usePortalApi();

    return portalApi.$post('/graphql/', {
      query: allCities.loc?.source.body
    });
  };

  const getReglament = async () => {
    if (!reglament.value.isFetched) {
      const url = documentsApiConfig.detail('reglament');
      const response = await useApiClient().$get(url);
      setReglament(response);

      return response;
    }

    return reglament.value.data;
  };

  const globalInit = async () => {
    const [citiesRes] = await Promise.all([
      getAllCities()
    ]);

    if (citiesRes.data?.currentCity) {
      activeCity.value = citiesRes.data.currentCity;
    }

    citiesGlobal.value = citiesRes?.data?.allCities?.edges?.length
      ? citiesRes.data.allCities.edges.map((item: {node: GlobalCity}) => item.node)
      : [];
  };

  const decoratedApiActions = decorateApiActions({
    globalInit,
    getAllCities,
    getReglament
  }, 'store/global.ts', true);

  return {
    activeCity,
    citiesGlobal,
    reglament,
    getReglamentLink,
    getReglamentText,
    getActiveCity,
    getGlobalCities,
    ...decoratedApiActions
  };
});
