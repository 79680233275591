import { NuxtAxiosInstance } from '@nuxtjs/axios';
import getErrorMessageTyped
  from '@@/common/services/getErrorMessageForNotificationDataTyped';

interface RetryOptions {
  retryCount: number;
  retryStatusCode?: number;
  retryDelay?: number;
}
export const apiRetry = (instance: NuxtAxiosInstance, options: RetryOptions): void => {
  let counter = 0;
  let timer: ReturnType<typeof setTimeout>;

  const {
    retryCount,
    retryDelay = 1000
  } = options;

  instance.interceptors.response.use((response) => {
    counter = 0;
    clearTimeout(timer);

    return response;
  }, (error) => {
    const config = error.config;

    if (error?.response?.status) {
      counter = 0;
      clearTimeout(timer);

      return Promise.reject(error);
    }

    if (counter < retryCount) {
      counter++;

      return new Promise((resolve) => {
        timer = setTimeout(() => {
          resolve(instance(config));
        }, retryDelay);
      });
    }

    counter = 0;
    clearTimeout(timer);

    error.message = getErrorMessageTyped(error);

    return Promise.reject(error);
  });
};
